<template>
  <b-card :title="title">
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        v-for="log in logs"
        :key="log.id"
        :variant="log.status == 'rejected'? 'danger':'info'"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6 v-if="log.status == 'rejected'">
            تم رفض طلب التسجيل بتاريخ {{ formatDate(log.created_at) }} للأسباب التالية
          </h6>
          <h6 v-if="log.status == 'approved'">
            تم قبول طلب التسجيل بتاريخ {{ formatDate(log.created_at) }}
          </h6>
          <small class="text-muted">
            {{ dateDistance(log.created_at) }}
          </small>
        </div>
        <p>{{ log.message }}</p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import {
  formatDistance,
  subDays,
  format,
} from 'date-fns';
import { ar } from 'date-fns/locale';

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'سبب الرفض',
    },
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd MMMM yyyy', { locale: ar });
    },
    dateDistance(date) {
      return formatDistance(new Date(date), new Date(), {
        locale: ar,
        addSuffix: 'منذ',
      });
    },
  },
};
</script>

<style>

</style>
