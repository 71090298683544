<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="requestData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب البيانات
      </h4>
      <div class="alert-body">
        لم يتم العثور على طلب مع معرف الطلب هذا. راجع
        <b-link
          class="alert-link"
          :to="{ name: 'requests' }"
        >
          قائمة الطلبات
        </b-link>
        لطلبات أخرى.
      </div>
    </b-alert>

    <template v-if="requestData">
      <!-- First Row -->
      <b-row v-if="requestData.request.status != 'rejected' && requestData.request.logs.length > 0 ">
        <b-col
          cols="12"
          lg="12"
        >
          <request-view-request-timeline-card
            :logs="requestData.request.logs"
            title="تنبيه"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <request-view-request-info-card :request-data="requestData" />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <request-view-request-admin-procedure :request-data="requestData" />
        </b-col>
      </b-row>
      <b-row v-if="requestData.request.logs.length > 0 && requestData.request.status == 'rejected'">
        <b-col
          cols="12"
          lg="12"
        >
          <request-view-request-timeline-card :logs="requestData.request.logs" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue';
import requestStoreModule from '../requestStoreModule';
import RequestViewRequestInfoCard from './RequestViewRequestInfoCard.vue';
import RequestViewRequestAdminProcedure from './RequestViewRequestAdminProcedure.vue';
import RequestViewRequestTimelineCard from './RequestViewRequestTimelineCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    RequestViewRequestInfoCard,
    RequestViewRequestAdminProcedure,
    RequestViewRequestTimelineCard,
  },
  setup() {
    const requestData = ref(null);

    const REQUEST_APP_STORE_MODULE_NAME = 'app-request';

    // Register module
    if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME)) store.registerModule(REQUEST_APP_STORE_MODULE_NAME, requestStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME)) store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-request/fetchRequest', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { request } = response.data;
        requestData.value = response.data;
        router.currentRoute.meta.breadcrumb = [
          {
            text: 'الرئيسية',
            to: '/',
          },
          {
            text: 'الطلبات',
            to: '/requests',
          },
          {
            text: request.company_name,
            active: true,
          },
        ];
        console.log(router.currentRoute.meta.breadcrumb);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          requestData.value = undefined;
        }
      });

    return {
      requestData,
    };
  },
};
</script>

<style>
</style>
