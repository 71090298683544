<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <b-card-title>الإجراء الإداري</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Col: Table -->
              <b-col
                cols="12"
                xl="12"
              >
                <b-form>
                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="is_owner_verified"
                      v-model="is_owner_verified"
                      :disabled="requestData.request.status != 'pending'"
                      name="is_owner_verified"
                    >
                      تم التحقق من بيانات مدير الشركة
                    </b-form-checkbox>
                  </b-form-group>
                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="is_company_verified"
                      v-model="is_company_verified"
                      :disabled="requestData.request.status != 'pending'"
                      name="is_company_verified"
                    >
                      تم التحقق من بيانات مدير الشركة
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-row>
                    <b-col
                      offset-md="6"
                      cols="6"
                    >
                      <div
                        v-if="requestData.request.status == 'pending'"
                        class="actions-btn float-right"
                      >
                        <b-button
                          v-b-modal.modal-refuse-request
                          class="mr-1"
                          variant="danger"
                        >
                          رفض الطلب
                        </b-button>
                        <b-button
                          type="submit"
                          variant="primary"
                          @click.prevent="updateRequest"
                        >
                          {{ updateRequestBtnLabel }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- modal login-->
    <b-modal
      id="modal-refuse-request"
      cancel-variant="outline-secondary"
      ok-title="رفض"
      cancel-title="إلغاء"
      centered
      title="رفض طلب التسجيل"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleRefuseRequest"
    >
      <b-form>
        <b-form-group>
          <label for="message">سبب رفض طلب التسجيل*</label>
          <b-form-textarea
            id="textarea"
            v-model="message"
            placeholder="سبب رفض طلب التسجيل"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BForm,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue';
import useRequestsList from '../requests-list/useRequestsList';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    BModal,
  },
  props: {
    requestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_owner_verified: false,
      is_company_verified: false,
      message: '',
    };
  },
  computed: {
    updateRequestBtnLabel() {
      return this.isVerified
        ? 'تم التحقق من كافة البيانات'
        : 'لم يتم التحقق من كافة البيانات';
    },
    isVerified() {
      return this.is_owner_verified && this.is_company_verified;
    },
  },
  methods: {
    handleRefuseRequest() {
      const response = this.$store
        .dispatch('app-request/refuseRequest', {
          request_id: this.requestData.request.id,
          message: this.message,
          is_owner_verified: this.is_owner_verified,
          is_company_verified: this.is_company_verified,
          status: 'rejected',
        })
        .then((response) => {
          this.resetModal();
          this.$router.push({
            name: 'requests-list',
          });
        });
    },
    resetModal() {
      this.message = '';
    },
    showModal() {
      this.$refs.modalRefuseRequest.show();
    },
    updateRequest() {
      const response = this.$store
        .dispatch('app-request/updateRequest', {
          request_id: this.requestData.request.id,
          message: this.isVerified ? 'تم التحقق من هوية مدير الشركة وبيانات الشركة ' : '',
          is_owner_verified: this.is_owner_verified,
          is_company_verified: this.is_company_verified,
          status: this.isVerified ? 'approved' : 'pending',
        })
        .then((response) => {
          this.resetModal();
          this.$router.push({
            name: 'requests-list',
          });
        });
    },
  },
  setup() {
    const { resolveAccountTypeLabel } = useRequestsList();
    return {
      resolveAccountTypeLabel,
    };
  },
};
</script>

<style>
</style>
