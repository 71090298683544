import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/request', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/request/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateRequest(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/request/${requestData.request_id}`, requestData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    refuseRequest(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/request/${requestData.request_id}/refuse`, requestData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
