import {
  ref,
  watch,
  computed,
} from '@vue/composition-api';
import store from '@/store';
import {
  title,
} from '@core/utils/filter';

// Notification
import {
  useToast,
} from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  formatDistance,
  subDays,
  format,
} from 'date-fns';
import { ar } from 'date-fns/locale';

export default function useRequestsList() {
  // Use toast
  const toast = useToast();

  const refRequestListTable = ref(null);

  // Table Handlers
  const tableColumns = [{
    key: 'created_at',
    label: 'تاريخ التسجيل',
    sortable: true,
  },
  {
    key: 'name',
    label: 'إسم العميل',
    sortable: true,
  },
  {
    key: 'status',
    label: 'الحالة',
    sortable: true,
  },
  {
    key: 'actions',
    label: 'العمليات',
  },
  ];
  const perPage = ref(10);
  const totalRequests = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refRequestListTable.value ? refRequestListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRequests.value,
    };
  });

  const refetchData = () => {
    refRequestListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchRequests = (ctx, callback) => {
    store
      .dispatch('app-request/fetchRequests', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        let {
          data: requests,
          total,
        } = response.data;
        requests = requests.map((request) => {
          console.log(format(new Date(request.created_at), 'MM/dd/yyyy'));
          request.created_at = format(new Date(request.created_at), 'MM/dd/yyyy');
          // `${formatDistance(subDays(new Date(), 3), new Date(request.created_at), {
          //   addSuffix: true,
          //   locale: ar
          // })}`
          return request;
        });
        callback(requests);
        totalRequests.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'خطأ في جلب قائمة الطلبات',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRequestStatusVariant = (status) => {
    if (status === 'pending') return 'warning';
    if (status === 'approved') return 'success';
    if (status === 'rejected') return 'danger';
    return 'primary';
  };

  const resolveRequestStatusLabel = (status) => {
    if (status === 'pending') return 'قيد الانتظار';
    if (status === 'approved') return 'مقبول';
    if (status === 'rejected') return 'مرفوض';
    return 'غير محدد';
  };

  const resolveAccountTypeLabel = (accountType) => {
    if (accountType === 'personal') return 'شخصي';
    if (accountType === 'company') return 'شركة';
    if (accountType === 'company_plus') return ' شركة بلاس';
    return 'غير محدد';
  };

  return {
    fetchRequests,
    tableColumns,
    perPage,
    currentPage,
    totalRequests,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRequestListTable,

    resolveRequestStatusVariant,
    resolveRequestStatusLabel,
    resolveAccountTypeLabel,
    refetchData,

    // Extra Filters
    statusFilter,
  };
}
