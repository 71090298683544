<template>
  <b-row>
    <b-col
      cols="12"
      xl="6"
      lg="6"
      md="6"
    >
      <b-card>
        <b-card-header>
          <b-card-title>معلومات مدير الشركة</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">إسم مدير الشركة</span>
                  </th>
                  <td class="pb-50">
                    {{ requestData.request.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FileTextIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">رقم رخصة التقييم</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ requestData.request.license_number }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="MailIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">البريد الالكتروني</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ requestData.request.email }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">الجوال</span>
                  </th>
                  <td class="pb-50">
                    {{ requestData.request.mobile }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      xl="6"
      lg="6"
      md="6"
    >
      <b-card>
        <b-card-header>
          <b-card-title>معلومات الشركة</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="AtSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">اسم الشركة</span>
                  </th>
                  <td class="pb-50">
                    {{ requestData.request.company_name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FileTextIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">رخصة التقييم</span>
                  </th>
                  <td class="pb-50">
                    {{ requestData.request.company_license_number }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FileTextIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">السجل التجاري</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ requestData.request.commercial_register }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">الهاتف</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ requestData.request.phone }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="PackageIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">نوع الاشتراك</span>
                  </th>
                  <td>
                    <b-badge
                      pill
                      :variant="`light-primary`"
                      class="text-capitalize"
                    >
                      {{ resolveAccountTypeLabel(requestData.request.account_type) }}
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BAvatar, BRow, BCol, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue';
import useRequestsList from '../requests-list/useRequestsList';

export default {
  components: {
    BCard, BCardHeader, BCardBody, BBadge, BButton, BRow, BCol, BAvatar, BCardTitle, BCardSubTitle,
  },
  props: {
    requestData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveAccountTypeLabel } = useRequestsList();
    return {
      resolveAccountTypeLabel,
    };
  },
};
</script>

<style>

</style>
